import React from "react"
import styled from 'styled-components'
import { Grid, Link, Margin, PageWrapper } from '@components/atoms'
import { type } from '@theme'


const FourOhFour = () => (
  <PageWrapper>
    <Margin>
      <StyledGrid>
        <Title>404</Title>
        <P>Hm. Not here. <Link to="/" >Perhaps there?</Link></P>
      </StyledGrid>
    </Margin>
  </PageWrapper>
)


const StyledGrid = styled(props => <Grid {...props} />)`
  grid-row-gap: 0;
`


const Title = styled.h1`
  text-align: center;
  ${type.headline1}
  grid-column-start: 2;
  grid-column-end: span 4;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 10;    
  }
  text-align: center;
`


const P = styled.p`
  grid-column-start: 2;
  grid-column-end: span 4;
  @media only screen and (min-width: 744px) {
    grid-column-end: span 10;    
  }
  text-align: center;
`


export default FourOhFour